import React from 'react';
import { Helmet } from 'react-helmet';
import I18n from '../utils/I18n';
import Layout from '../layouts/Layout';
import VerificationSearch from '../components/VerificationSearch';

export const VerificationTemplate = context => {
  const {
    pageContext: {
      lang,
      slug
    }
  } = context;

  const i18n = new I18n({ lang });

  return (
    <Layout title="Verify Credentials" lang={lang} slug={slug}>
      <Helmet>
        <script src={`https://www.google.com/recaptcha/api.js?render=explicit&hl=${lang}`} async defer></script>
      </Helmet>
      <div className="cs-page-verif_hero slds-is-relative">
        <div className="certification-banner_breadcrumb">
          <a href={`/${lang}/credentials/administratoroverview`}>{i18n.text('breadcrumb_root')}</a> &gt; {i18n.text('verification_title')}
        </div>

        <div className="cs-page-verif_hero_content">
          <div>
            <div className="cs-page-verif_hero_title slds-container--center slds-text-align--center">
              <h1>{i18n.text('verification_title')}</h1>
            </div>
            <div className="slds-container--medium slds-container--center slds-text-align--center slds-m-top--medium slds-m-bottom--large slds-p-horizontal_small Fz(18px) Lh(1.5)">
              <h3>{i18n.text('verification_description')}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="cert-site_bg-gray-icons slds-p-bottom--x-large slds-p-top_xx-large Mih(680px)">
        <VerificationSearch lang={lang} />
      </div>

    </Layout>
  );
};

export default VerificationTemplate;
